.banner {
  height: 50vh;
  position: relative;
  width: 100%;
  z-index: -1;

  &__bg_video {
    height: 100%;
    left: 0;
    opacity: 0.5;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;

    &__content {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__logo {
    height: 150px;
    width: 150px;
  }

  &__description-box {
    align-items: center;
    background-color: rgba(white, 0.9);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: space-evenly;
    width: 400px;
    position: absolute;
    padding: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include respond(tab-land) {
      height: 270px;
      width: 270px;
    }
    @include respond(sm-phone) {
      height: 275px;
      width: 275px;
    }
  }

  &__primary-heading {
    color: $main-text;

    @include respond(tab-land) {
      font-size: 12px;
    }

    @include respond(phone) {
      font-size: 16px;
    }
  }

  &__secondary-heading {
    color: $main-gold;

    @include respond(tab-land) {
      font-size: 8px;
    }
    @include respond(phone) {
      font-size: 8px;
    }
  }
}
