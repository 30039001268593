.mentee {
  background-color: white;
  border-bottom: 1px solid $main-gold;
  color: $main-text;
  height: 100vh;
  padding: 10vh;
  width: 100%;

  @include respond(phone) {
    padding: 0px 10vw;
  }

  @include respond(sm-phone) {
    height: 150vh;
  }

  &__title {
    text-align: center;
    @include respond(phone) {
      padding: 3vh 0px;
    }
  }

  &__container {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }

  &__grid {
    position: absolute;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(7, 1fr);
  }

  &__image {
    height: 100%;
    width: 100%;
  }

  &__image-container {
    grid-column: 1 / 3;
    grid-row: 2 / 7;
    @include respond(tab-land) {
      grid-column: 1 / 4;
    }

    @include respond(phone) {
      grid-column: 1 / 10;
      grid-row: 1 / 5;
    }
  }

  &__description-one {
    letter-spacing: 1.2px;
    grid-column: 4 / 7;
    grid-row: 2/3;
    @include respond(tab-land) {
      grid-column: 5/8;
    }

    @include respond(phone) {
      grid-column: 1 / 10;
      grid-row: 5 / 6;
      letter-spacing: 1px;
    }
  }

  &__description-two {
    letter-spacing: 1.2px;
    grid-column: 4 / 7;
    grid-row: 5/6;
    @include respond(tab-land) {
      grid-column: 5/8;
    }

    @include respond(phone) {
      grid-column: 1 / 8;
      grid-row: 6 / 8;
      letter-spacing: 1px;
    }
  }
}
