.contact {
  border-bottom: 1px solid $main-gold;
  color: $main-text;
  height: 75vh;
  padding: 10vh;
  width: 100%;

  @include respond(tab-land) {
    height: 125vh;
  }

  @include respond(phone) {
    padding: 5vh 10vw;
    height: 100vh;
  }

  &__title {
    text-align: center;
  }

  &__form {
    background-color: $main-gold;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    justify-content: space-evenly;
    margin-top: 5px;
    text-align: center;
    padding: 10px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 50%;

    @include respond(tab-land) {
      margin-top: 0px;
      width: auto;
    }

    @include respond(phone) {
      height: 90%;
      width: fit-content;
    }

    &__button {
      background-color: white;
      border: 3px solid $main-text;
      color: $main-text;
      height: 50px;
      margin: 0 auto;
      width: 100px;
      @include respond(phone) {
        height: 25px;
        width: 100px;
      }
    }

    &__button:hover {
      box-shadow: 10px 5px 5px $main-text;
      border: 3px solid black;
    }

    &__input {
      border-radius: 3px;
      margin-left: 5px;

      @include respond(phone) {
        width: 100%;
        margin: 0;
      }

      &__text {
        height: 100px;
        margin: 5px;

        @include respond(phone) {
          height: 20%;
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}
