.footer {
  background-color: white;
  color: $main-gold;
  text-align: center;
  padding: 10px 0px;
  height: fit-content;
  width: 100%;

  &__email {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px;
  }

  &__icon {
    font-size: 32px;
    padding-right: 5px;
  }
}

a {
  color: $main-gold;
  text-decoration: none;
}
