.nav {
  background-color: white;
  border-bottom: 3px solid $main-gold;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0.25s;
  width: 100%;
  z-index: 2;

  @include respond(phone) {
    background-color: #d9d8d6;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0;
    @include respond(phone) {
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
    }
  }

  ul li {
    list-style: none;
    text-align: center;
    width: fit-content;
    @include respond(phone) {
      height: fit-content;
      width: fit-content;
    }
  }

  ul li a {
    background: transparent;
    color: black;
    font-weight: bold;
    height: 60px;
    letter-spacing: 2px;
    line-height: 60px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.2s;
    width: 300px;
  }

  ul li a:hover {
    color: $main-gold;
  }

  ul li a:before {
    content: "";
    height: 100%;
    left: -3px;
    mix-blend-mode: multiply;
    position: absolute;
    top: -3px;
    transform-origin: top;
    width: 100%;
    z-index: -1;
  }

  ul li a:hover:before {
    background-color: $main-gold;
    left: 0;
    top: -6px;
    transform: perspective(1000px) rotateX(75deg);
  }
  ul li a:hover:after {
    background-color: $main-gold;
    left: 0;
    top: 6px;
    transform: perspective(1000px) rotateX(-75deg);
  }

  ul li a:after {
    content: "";
    height: 100%;
    left: 3px;
    mix-blend-mode: multiply;
    position: absolute;
    top: 3px;
    transition: all 0.2s;
    transform-origin: bottom;
    width: 100%;
    z-index: -1;
  }
}

.mobile {
  visibility: hidden;
  @include respond(phone) {
    align-items: center;
    background-color: $main-gold;
    box-shadow: 10px 5px 5px $main-text;
    border-radius: 80%;
    display: flex;
    height: 50px;
    justify-content: center;
    margin-right: 85vw;
    margin-top: 10px;
    visibility: visible;
    width: 50px;
    z-index: 3;
  }
}

.hamburger,
.close {
  cursor: pointer;
  height: 25px;
  width: 25px;
  transition: 0.1s ease-in-out;
}

.hidden {
  visibility: hidden;
}

.show {
  visibility: visible;
  transition: all 0.1s ease-in;
}

li a .hidden {
  transition: 0.1s ease-out;
}
