*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  // This defines what 1 rem is
  font-size: 90%; // 1rem = 12px;
  scroll-behavior: smooth;
  background-color: white;

  // width < 1200px
  @include respond(tab-land) {
    font-size: 85%;
  }

  // width < 900px
  @include respond(tab-port) {
    font-size: 70%;
  }

  //width > 1200px
  @include respond(big-desk) {
    font-size: 100%;
  }

  // width < 600px
  @include respond(phone) {
    font-size: 70%;
  }

  // width < 400px
  @include respond(sm-phone) {
    font-size: 60%;
  }
}

body {
  box-sizing: border-box;
  padding: 0.1rem;
}
