.about {
  background-color: white;
  border-bottom: 1px solid $main-gold;
  color: $main-text;
  height: 100vh;
  letter-spacing: 1.2px;
  padding: 10vh;
  width: 100%;

  @include respond(tab-land) {
    height: 125vh;
  }

  @include respond(phone) {
    height: 150vh;
    padding: 0px 10vw;
  }

  @include respond(sm-phone) {
    height: 200vh;
  }

  &__title {
    text-align: center;
    @include respond(phone) {
      padding: 3vh 0px;
    }
  }

  &__container {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }

  &__grid {
    position: absolute;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(7, 1fr);

    @include respond(tab-land) {
      height: auto;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
  }

  &__description {
    color: $main-text;
    grid-column: 5 / 8;
    grid-row: 5 / 8;
    padding-top: 20px;
    @include respond(tab-land) {
      grid-column: 5/ 8;
      grid-row: 4 / 8;
    }

    @include respond(phone) {
      padding-top: 0px;
      grid-column: 1 / 8;
      grid-row: 2 / 3;
    }
  }

  &__second-header {
    grid-row: 2;
    grid-column: 2 / 4;

    @include respond(tab-land) {
      grid-column: 1 / 3;
      grid-row: 1;
    }

    @include respond(phone) {
      grid-row: 3;
      grid-column: 1/8;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }

  &__list {
    grid-row: 3 / 7;
    grid-column: 1 / 4;

    @include respond(tab-land) {
      grid-column: 1 / 4;
      grid-row: 2 / 8;
    }

    @include respond(phone) {
      grid-row: 4 / 7;
      grid-column: 1 / 8;
    }

    &__item {
      padding: 2px;

      @include respond(tab-land) {
        padding: 2px;
      }

      @include respond(tab-port) {
        padding: 0px;
      }

      @include respond(phone) {
        padding: 5px;
      }
    }
  }

  &__image-container {
    grid-column: 5 / 8;
    grid-row: 2 / 5;
    @include respond(tab-land) {
      grid-column: 5/ 9;
      grid-row: 1 / 4;
    }

    @include respond(phone) {
      grid-column: 1 / 8;
      grid-row: 1;
    }
  }
}
