@mixin respond($breakpoint) {
  // 300px
  @if $breakpoint == xs {
    @media screen and (max-width: 18.75em) {
      @content;
    }
  }

  // 400px
  @if $breakpoint == sm-phone {
    @media only screen and (max-width: 25em) {
      @content;
    }
  }

  // 600px
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    // 900 px
    @media only screen and (max-width: 56.25em) {
      @content;
    }
  }

  @if $breakpoint == tab-land {
    // 1200px
    @media only screen and (max-width: 75em) {
      @content;
    }
  }

  @if $breakpoint == big-desk {
    // 1800px
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}
