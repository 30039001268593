.app {
  @include respond(big-desk) {
    max-width: 1280px;
    max-height: 720px;
    margin: 0 auto;
  }
}

.dummy {
  background-color: white;
  height: 7vh;
  width: 100%;
  @include respond(phone) {
    height: 30vh;
  }
  @include respond(sm-phone) {
    height: 40vh;
  }
}
